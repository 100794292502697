import { put, call, takeLatest, all } from "redux-saga/effects";
import { settingActions } from "./settingSlice";
import routeConfigApi from "@/api/setting/routeConfig";
import { toError } from "@/shared/utils/toError";
import { PayloadAction } from "@reduxjs/toolkit";
import { IIntegrationSetup, IReason, IRouterConfiguration, IUser, IVehicleVariant } from "@/shared/interfaces/setting";
import vehicleApi from "@/api/setting/vehicle";
import reasonApi from "@/api/setting/reason";
import depotApi from "@/api/setting/depot";
import userApi from "@/api/setting/user";
import routeRuleApi from "@/api/setting/routeRule";
import customerApi from "@/api/setting/customer";
import integrationSetupApi from "@/api/setting/integrationSetup";
import { toast } from "react-toastify";
import { convertTimeFromUTC, convertTimeToUTC } from "@/shared/utils/date";

function* watchGetRouterConfigs() {
  try {
    const { data } = yield call(routeConfigApi.getRouteConfigs);
    yield put(settingActions.getRouterConfigsSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.getRouterConfigsFailed(err));
  }
}

function* watchGetSuggestions(): any {
  try {
    const [vehicles, depots, vehicleVariants] = yield all([
      call(vehicleApi.getVehicles),
      call(depotApi.getDepots),
      call(vehicleApi.getVehicleVariants),
    ]);
    yield put(
      settingActions.getSuggestionsSuccess({
        vehicles: vehicles.data,
        depots: depots.data,
        vehicleVariants: vehicleVariants.data,
      })
    );
  } catch (error) {
    const err = toError(error);
    yield put(settingActions.getRouterConfigsFailed(err));
  }
}

function* watchCreateRouterConfig(action: PayloadAction<IRouterConfiguration>) {
  try {
    const { data } = yield call(routeConfigApi.createRouteConfig, convertTimeToUTC(action.payload));
    yield put(settingActions.createRouterConfigSuccess(data));
    toast.success("Created Successfully");
    yield watchGetRouterConfigs();
  } catch (error) {
    const err = toError(error);
    toast.error("Error server");
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetRouterConfig(action: PayloadAction<number>) {
  try {
    const { data } = yield call(routeConfigApi.getRouteConfig, action.payload);
    yield put(settingActions.getRouterConfigSuccess(convertTimeFromUTC(data)));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateRouterConfig(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(routeConfigApi.updateRouteConfig, id, convertTimeToUTC(payload));
    yield put(settingActions.updateRouterConfigSuccess());
    toast.success("Updated Successfully");
    yield watchGetRouterConfigs();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetVehicles() {
  try {
    const { data } = yield call(vehicleApi.getVehicles);
    yield put(settingActions.getVehiclesSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error("Error server");
    yield put(settingActions.requestFailed(err));
  }
}

function* watchCreateVehicle(action: PayloadAction<IRouterConfiguration>) {
  try {
    const { data } = yield call(vehicleApi.createVehicle, action.payload);
    yield put(settingActions.createVehicleSuccess(data));
    toast.success("Created Successfully");
    yield watchGetVehicles();
  } catch (error) {
    const err = toError(error);
    toast.error("Vehicle with registration number already exists");
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetVehicle(action: PayloadAction<number>) {
  try {
    const { data } = yield call(vehicleApi.getVehicle, action.payload);
    yield put(settingActions.getVehicleSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateVehicle(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(vehicleApi.updateVehicle, id, payload);
    yield put(settingActions.updateVehicleSuccess());
    toast.success("Updated Successfully");
    yield watchGetVehicles();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetReasons() {
  try {
    const { data } = yield call(reasonApi.getReasons);
    yield put(settingActions.getReasonsSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.getRouterConfigsFailed(err));
  }
}

function* watchGetReason(action: PayloadAction<number>) {
  try {
    const { data } = yield call(reasonApi.getReason, action.payload);
    yield put(settingActions.getReasonSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchCreateReason(action: PayloadAction<IRouterConfiguration>) {
  try {
    const { data } = yield call(reasonApi.createReason, action.payload);
    yield put(settingActions.createReasonSuccess(data));
    toast.success("Created Successfully");
    yield watchGetReasons();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateReason(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(reasonApi.updateReason, id, payload);
    yield put(settingActions.updateReasonSuccess());
    toast.success("Updated Successfully");
    yield watchGetReasons();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchRemoveReason(action: PayloadAction<number>) {
  try {
    yield call(reasonApi.removeReason, action.payload);
    yield put(settingActions.removeReasonSuccess());
    toast.success("Remove successfully");
    yield watchGetReasons();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateReasons(action: PayloadAction<IReason[]>) {
  try {
    yield call(
      reasonApi.updateReasonSequence,
      action.payload.map((r) => r.id)
    );
    yield put(settingActions.updateReasonSuccess());
    toast.success("Updated successfully");
    yield watchGetReasons();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetDepots() {
  try {
    const { data } = yield call(depotApi.getDepots);
    yield put(settingActions.getDepotsSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.getRouterConfigsFailed(err));
  }
}

function* watchGetDepot(action: PayloadAction<number>) {
  try {
    const { data } = yield call(depotApi.getDepot, action.payload);
    yield put(settingActions.getDepotSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchCreateDepot(action: PayloadAction<IRouterConfiguration>) {
  try {
    const { data } = yield call(depotApi.createDepot, action.payload);
    yield put(settingActions.createDepotSuccess(data));
    toast.success("Created Successfully");
    yield watchGetDepots();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateDepot(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(depotApi.updateDepot, id, payload);
    yield put(settingActions.updateDepotSuccess());
    toast.success("Updated Successfully");
    yield watchGetDepots();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetUsers(action?: PayloadAction<number>) {
  try {
    const { data } = yield call(userApi.getUsers, action?.payload);
    yield put(settingActions.getUsersSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetUser(action: PayloadAction<number>) {
  try {
    const { data } = yield call(userApi.getUser, action.payload);
    yield put(settingActions.getUserSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchCreateUser(action: PayloadAction<IUser>) {
  try {
    const { data } = yield call(userApi.createUser, action.payload);
    yield put(settingActions.createUserSuccess(data));
    toast.success("Created Successfully");
    yield watchGetUsers();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateUser(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(userApi.updateUser, id, payload);
    yield put(settingActions.updateUserSuccess());
    toast.success("Updated Successfully");
    yield watchGetUsers();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchRemoveUser(action: PayloadAction<number>) {
  try {
    yield call(userApi.removeUser, action.payload);
    yield put(settingActions.deleteUserSuccess());
    toast.success("Remove successfully");
    yield watchGetUsers();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetRouteRules(action?: PayloadAction<number>) {
  try {
    const { data } = yield call(routeRuleApi.getRouteRules, action?.payload);
    yield put(settingActions.getRouteRulesSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchCreateRouteRule(action: PayloadAction<IUser>) {
  try {
    const { data } = yield call(routeRuleApi.createRouteRule, action.payload);
    yield put(settingActions.createRouteRuleSuccess(data));
    toast.success("Created Successfully");
    yield watchGetRouteRules();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetRouteRule(action: PayloadAction<number>) {
  try {
    const { data } = yield call(routeRuleApi.getRouteRule, action.payload);
    yield put(settingActions.getRouteRuleSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateRouteRule(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(routeRuleApi.updateRouteRule, id, payload);
    yield put(settingActions.updateRouteRuleSuccess());
    toast.success("Updated Successfully");
    yield watchGetRouteRules();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetCustomers(action?: PayloadAction<number>) {
  try {
    const { data } = yield call(customerApi.getCustomers, action?.payload);
    yield put(settingActions.getCustomersSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchCreateCustomer(action: PayloadAction<IUser>) {
  try {
    const { data } = yield call(customerApi.createCustomer, action.payload);
    yield put(settingActions.createCustomerSuccess(data));
    toast.success("Created Successfully");
    yield watchGetCustomers();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateCustomer(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(customerApi.updateCustomer, id, payload);
    yield put(settingActions.updateCustomerSuccess());
    toast.success("Updated Successfully");
    yield watchGetCustomers();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetCustomer(action: PayloadAction<number>) {
  try {
    const { data } = yield call(customerApi.getCustomer, action.payload);
    yield put(settingActions.getCustomerSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchRemoveCustomer(action: PayloadAction<number>) {
  try {
    yield call(customerApi.removeCustomer, action.payload);
    yield put(settingActions.removeCustomerSuccess());
    toast.success("Removed Successfully");
    yield watchGetCustomers();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetVehicleVariants() {
  try {
    const { data } = yield call(vehicleApi.getVehicleVariants);
    yield put(settingActions.getVehicleVariantsSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetVehicleVariant(action: PayloadAction<number>) {
  try {
    const { data } = yield call(vehicleApi.getVehicleVariant, action.payload);
    yield put(settingActions.getVehicleVariantSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchRemoveVehicleVariant(action: PayloadAction<number>) {
  try {
    yield call(vehicleApi.removeVehicleVariant, action.payload);
    yield put(settingActions.removeVehicleVariantSuccess());
    yield watchGetVehicleVariants();
    toast.success("Removed Successfully");
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchCreateVehicleVariant(action: PayloadAction<IVehicleVariant>) {
  try {
    const { data } = yield call(vehicleApi.createVehicleVariant, action.payload);
    yield put(settingActions.createVehicleVariantSuccess(data));
    toast.success("Created Successfully");
    yield watchGetVehicleVariants();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateVehicleVariant(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(vehicleApi.updateVehicleVariant, id, payload);
    yield put(settingActions.updateVehicleVariantSuccess());
    toast.success("Updated Successfully");
    yield watchGetVehicleVariants();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetIntegrationSetups() {
  try {
    const { data } = yield call(integrationSetupApi.getIntegrationSetups);
    yield put(settingActions.getIntegrationSetupsSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchCreateIntegrationSetup(action: PayloadAction<IIntegrationSetup>) {
  try {
    const { data } = yield call(integrationSetupApi.createIntegrationSetup, action.payload);
    yield put(settingActions.createIntegrationSetupSuccess(data));
    toast.success("Created Successfully");
    yield watchGetIntegrationSetups();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchGetIntegrationSetup(action: PayloadAction<number>) {
  try {
    const { data } = yield call(integrationSetupApi.getIntegrationSetup, action.payload);
    yield put(settingActions.getIntegrationSetupSuccess(data));
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchUpdateIntegrationSetup(action: PayloadAction<any>) {
  const { id, data: payload } = action.payload;
  try {
    yield call(integrationSetupApi.updateIntegrationSetup, id, payload);
    yield put(settingActions.updateIntegrationSetupSuccess());
    toast.success("Updated Successfully");
    yield watchGetIntegrationSetups();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

function* watchRemoveIntegrationSetup(action: PayloadAction<number>) {
  try {
    yield call(integrationSetupApi.removeIntegrationSetup, action.payload);
    yield put(settingActions.removeIntegrationSetupSuccess());
    toast.success("Removed Successfully");
    yield watchGetIntegrationSetups();
  } catch (error) {
    const err = toError(error);
    toast.error(err.message);
    yield put(settingActions.requestFailed(err));
  }
}

export default function* settingSaga() {
  yield takeLatest(settingActions.getRouterConfigs.type, watchGetRouterConfigs);
  yield takeLatest(settingActions.getSuggestions.type, watchGetSuggestions);
  yield takeLatest(settingActions.createRouterConfig.type, watchCreateRouterConfig);
  yield takeLatest(settingActions.getRouterConfig.type, watchGetRouterConfig);
  yield takeLatest(settingActions.updateRouterConfig.type, watchUpdateRouterConfig);
  yield takeLatest(settingActions.getVehicles.type, watchGetVehicles);
  yield takeLatest(settingActions.createVehicle.type, watchCreateVehicle);
  yield takeLatest(settingActions.getVehicle.type, watchGetVehicle);
  yield takeLatest(settingActions.updateVehicle.type, watchUpdateVehicle);
  yield takeLatest(settingActions.getReasons.type, watchGetReasons);
  yield takeLatest(settingActions.getReason.type, watchGetReason);
  yield takeLatest(settingActions.createReason.type, watchCreateReason);
  yield takeLatest(settingActions.updateReason.type, watchUpdateReason);
  yield takeLatest(settingActions.removeReason.type, watchRemoveReason);
  yield takeLatest(settingActions.updateReasonSequence.type, watchUpdateReasons);
  yield takeLatest(settingActions.getDepots.type, watchGetDepots);
  yield takeLatest(settingActions.getDepot.type, watchGetDepot);
  yield takeLatest(settingActions.createDepot.type, watchCreateDepot);
  yield takeLatest(settingActions.updateDepot.type, watchUpdateDepot);
  yield takeLatest(settingActions.getUsers.type, watchGetUsers);
  yield takeLatest(settingActions.getUser.type, watchGetUser);
  yield takeLatest(settingActions.createUser.type, watchCreateUser);
  yield takeLatest(settingActions.updateUser.type, watchUpdateUser);
  yield takeLatest(settingActions.deleteUser.type, watchRemoveUser);
  yield takeLatest(settingActions.getRouteRules.type, watchGetRouteRules);
  yield takeLatest(settingActions.createRouteRule.type, watchCreateRouteRule);
  yield takeLatest(settingActions.getRouteRule.type, watchGetRouteRule);
  yield takeLatest(settingActions.updateRouteRule.type, watchUpdateRouteRule);
  yield takeLatest(settingActions.getCustomers.type, watchGetCustomers);
  yield takeLatest(settingActions.createCustomer.type, watchCreateCustomer);
  yield takeLatest(settingActions.updateCustomer.type, watchUpdateCustomer);
  yield takeLatest(settingActions.getCustomer.type, watchGetCustomer);
  yield takeLatest(settingActions.removeCustomer.type, watchRemoveCustomer);
  yield takeLatest(settingActions.getVehicleVariants.type, watchGetVehicleVariants);
  yield takeLatest(settingActions.getVehicleVariant.type, watchGetVehicleVariant);
  yield takeLatest(settingActions.removeVehicleVariant.type, watchRemoveVehicleVariant);
  yield takeLatest(settingActions.createVehicleVariant.type, watchCreateVehicleVariant);
  yield takeLatest(settingActions.updateVehicleVariant.type, watchUpdateVehicleVariant);
  yield takeLatest(settingActions.getIntegrationSetups.type, watchGetIntegrationSetups);
  yield takeLatest(settingActions.createIntegrationSetup.type, watchCreateIntegrationSetup);
  yield takeLatest(settingActions.getIntegrationSetup.type, watchGetIntegrationSetup);
  yield takeLatest(settingActions.updateIntegrationSetup.type, watchUpdateIntegrationSetup);
  yield takeLatest(settingActions.removeIntegrationSetup.type, watchRemoveIntegrationSetup);
}
