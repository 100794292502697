import { LoginResponse, AuthCodePayload, LoginPayload, ErrorData, SetNewPasswordPasswordPayload, ResetPasswordPayload } from "@/shared/interfaces";
import { ResetPasswordRequestPayload, SetNewResetPasswordPayload, UserInfoResponse } from "@/shared/interfaces/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  loading: boolean;
  authorizationToken: string | number | null;
  role: string | null;
}

const initialState: AuthState = {
  loading: false,
  authorizationToken: null,
  role: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginPayload>) => {
      state.loading = true;
    },
    loginSuccess: (state, action: PayloadAction<LoginResponse>) => {
      state.loading = false;
      state.authorizationToken = action.payload.authorizationToken;
    },
    getMyselfSuccess: (state, action: PayloadAction<UserInfoResponse>) => {
      state.loading = false;
      state.role = action.payload.role;
    },
    verifyAuthCode: (state, action: PayloadAction<AuthCodePayload>) => {
      state.loading = true;
    },
    verifyAuthCodeSuccess: (state) => {
      state.loading = false;
    },
    setNewPassword: (state, action: PayloadAction<SetNewPasswordPasswordPayload>) => {
      state.loading = true;
    },
    setNewPasswordSuccess: (state) => {
      state.loading = false;
    },
    setNewResetPassword: (state, action: PayloadAction<SetNewResetPasswordPayload>) => {
      state.loading = true;
    },
    setNewResetPasswordSuccess: (state) => {
      state.loading = false;
    },
    resetPassword: (state, action: PayloadAction<ResetPasswordPayload>) => {
      state.loading = true;
    },
    resetPasswordSuccess: (state) => {
      state.loading = false;
    },
    resetPasswordRequest: (state, action: PayloadAction<ResetPasswordRequestPayload>) => {
      state.loading = true;
    },
    resetPasswordRequestSuccess: (state) => {
      state.loading = false;
    },
    requestFailed: (state) => {
      state.loading = false;
    },
    reset: () => initialState,
  },
});

// Auth actions
export const authActions = authSlice.actions;

// Auth reducer
export default authSlice.reducer;
