import axiosClient from "@/core/axios";
import { IResponse } from "@/shared/interfaces";
import { IVehicleVariant } from "@/shared/interfaces/setting";

const vehicleVariantApi = {
  getVehicleVariants: (): Promise<IResponse<IVehicleVariant>> => {
    const url = "/vehicle-variants";
    return axiosClient.get(url);
  },
};

export default vehicleVariantApi;
