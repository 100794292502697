import axios from "axios";
import { getAccessToken, getToken, removeAccessToken, removeToken } from "./token";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 200000,
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = getToken() || getAccessToken();
    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    //Handle error
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //Handle error
    const token = getAccessToken() || getToken();
    if (token && error?.response?.data?.statusCode === 401) {
      removeAccessToken();
      removeToken();
      location.assign("/login");
    }

    return Promise.reject(error);
  }
);
export default axiosClient;
