import { IMenuHeader } from "../interfaces";

const menuHeaders: IMenuHeader = {
  "/deliveries": {
    label: "DELIVERY ROUTES",
  },
  "/histories": {
    label: "HISTORY",
  },
  // "/reports": {
  //   label: "REPORTS",
  // },
  "/settings": {
    label: "SETTINGS",
  },
};

export default menuHeaders;
