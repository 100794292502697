import axiosClient from "@/core/axios";
import { IResponse } from "@/shared/interfaces";

const depotApi = {
  getDepots: (): Promise<IResponse<any>> => {
    const url = "/depots";
    return axiosClient.get(url);
  },
  getDepot: (id: number): Promise<IResponse<any>> => {
    const url = `/depots/${id}`;
    return axiosClient.get(url);
  },
  createDepot: (data: any): Promise<IResponse<any>> => {
    const url = "/depots";
    return axiosClient.post(url, data);
  },
  updateDepot: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/depots/${id}`;
    return axiosClient.patch(url, data);
  },
};

export default depotApi;
