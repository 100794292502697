import { Redirect, Route } from "react-router-dom";
import { getToken, getAccessToken, getRole } from "@/core/token";

const GuardRoute = ({ isPrivate, ...rest }: any) => {
  const token = getToken() || getAccessToken();
  const role = getRole();
  if (!token && isPrivate) {
    return <Redirect to="/login" />;
  }
  if (token && !isPrivate) {
    return <Redirect to="/deliveries" />;
  }
  if (rest.path === "/") {
    return <Redirect to="/deliveries" />;
  }
  return <Route {...rest} />;
};

export default GuardRoute;
