import { ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const depotActions = {
  getDepots: (state: ISettingState) => {
    state.loading = true;
  },
  getDepotsSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.depots = action.payload;
  },
  getDepot: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getDepotSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.depot = action.payload;
  },
  createDepot: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createDepotSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.success = "Created Successfully";
  },
  updateDepot: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  updateDepotSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully!";
  },
  resetDepot: (state: ISettingState) => {
    state.depot = undefined;
  },
};
