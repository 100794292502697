import axiosClient from "@/core/axios";
import { AddRouteStopPayload, IResponse } from "@/shared/interfaces";

const routeStopApi = {
  createRouteStop: (data: AddRouteStopPayload): Promise<IResponse<any>> => {
    const url = "/route-stops";
    return axiosClient.post(url, data);
  },
  deleteRouteStop: (id: number): Promise<IResponse<any>> => {
    const url = `/route-stops/${id}`;
    return axiosClient.delete(url);
  },
};

export default routeStopApi;
