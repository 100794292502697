import axiosClient from "@/core/axios";
import { IResponse } from "@/shared/interfaces";
import { DeliveryAttempt } from "@/shared/interfaces/deliveryAttempts";

const deliveryAttemptsApi = {
  getDeliveryAttempts: (): Promise<IResponse<DeliveryAttempt>> => {
    const url = "/delivery-attempts";
    return axiosClient.get(url);
  },
  getDeliveryNeedAction: (): Promise<IResponse<DeliveryAttempt>> => {
    const url = "/delivery-attempts/need-action";
    return axiosClient.get(url);
  },
  getDeliveryAttempt: (id: number): Promise<IResponse<DeliveryAttempt>> => {
    const url = `/delivery-attempts/${id}`;
    return axiosClient.get(url);
  },
  updateDeliveryAttempt: ({ id, payload }: { id: number; payload: DeliveryAttempt }) => {
    const url = `/delivery-attempts/${id}`;
    return axiosClient.patch(url, payload);
  },
  disconnectRoute: (id: number) => {
    const url = `/delivery-attempts/${id}/disconnect-route`;
    return axiosClient.patch(url);
  },
  bulkRouteConnect: (data: { ids: string[]; routeId: number }): Promise<any> => {
    const url = "/delivery-attempts/bulk-route-connect";
    return axiosClient.post(url, data);
  },
};

export default deliveryAttemptsApi;
