import axiosClient from "@/core/axios";
import { IResponse } from "@/shared/interfaces";

const integrationSetupApi = {
  getIntegrationSetups: (): Promise<IResponse<any>> => {
    const url = "/api-keys";
    return axiosClient.get(url);
  },
  createIntegrationSetup: (data: any): Promise<IResponse<any>> => {
    const url = "/api-keys";
    return axiosClient.post(url, data);
  },
  getIntegrationSetup: (id: number): Promise<IResponse<any>> => {
    const url = `/api-keys/${id}`;
    return axiosClient.get(url);
  },
  updateIntegrationSetup: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/api-keys/${id}`;
    return axiosClient.patch(url, data);
  },
  removeIntegrationSetup: (id: number): Promise<IResponse<any>> => {
    const url = `/api-keys/${id}`;
    return axiosClient.delete(url);
  },
};

export default integrationSetupApi;
