import axiosClient from "@/core/axios";
import { IResponse, RoutesQueryParams } from "@/shared/interfaces";
import { DeliveryRoute, RouteResponse } from "@/shared/interfaces/route";
import { IRouterConfiguration } from "@/shared/interfaces/setting";

const routeApi = {
  getRoutes: (params?: RoutesQueryParams): Promise<IResponse<RouteResponse>> => {
    const url = "/routes";
    return axiosClient.get(url, {
      params,
    });
  },
  getRoute: (id: number): Promise<IResponse<DeliveryRoute>> => {
    const url = `routes/${id}`;
    return axiosClient.get(url);
  },
  refreshRoute: (id: number): Promise<IResponse<DeliveryRoute>> => {
    const url = `routes/${id}/refresh-route`;
    return axiosClient.get(url);
  },
  getRouteOptimise: (id: number): Promise<IResponse<DeliveryRoute>> => {
    const url = `routes/${id}/optimise-stops`;
    return axiosClient.get(url);
  },
  getSummaryPdf: (id: number): Promise<IResponse<any>> => {
    const url = `routes/generate-summary/${id}`;
    return axiosClient.get(url);
  },
  getDeliveryDocketPdf: (id: number): Promise<IResponse<any>> => {
    const url = `routes/generate-delivery-docket/${id}`;
    return axiosClient.get(url);
  },
  getDeliveryDocketForRouteStopPdf: (id: number): Promise<IResponse<any>> => {
    const url = `routes/generate-delivery-docket-for-route-stop/${id}`;
    return axiosClient.get(url);
  },
  createRoute: (data: { routeConfig: IRouterConfiguration }): Promise<IResponse<any>> => {
    const url = "/routes";
    return axiosClient.post(url, data);
  },
  changeSequence: ({ id, payload }: { id: number; payload: DeliveryRoute }): Promise<IResponse<any>> => {
    const url = `routes/${id}/change-sequence`;
    return axiosClient.patch(url, payload);
  },
  updateRoute: ({ id, payload }: { id: number; payload: DeliveryRoute }): Promise<IResponse<any>> => {
    const url = `routes/${id}`;
    return axiosClient.patch(url, payload);
  },
  deleteRoute: (id: number) => {
    const url = `routes/${id}`;
    return axiosClient.delete(url);
  },
  getRouteVehicles: (id: number) => {
    const url = `vehicles/route/${id}`;
    return axiosClient.get(url);
  },
  recalculateRoute: (id: number) => {
    const url = `/routes/${id}/recalculate-route`;
    return axiosClient.patch(url);
  },
  removeRouteFromTrackPOD: (id: number) => {
    const url = `/routes/${id}/remove-from-pod`;
    return axiosClient.get(url);
  },
};

export default routeApi;
