import { ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const vehicleActions = {
  getVehicles: (state: ISettingState) => {
    state.loading = true;
  },
  getVehiclesSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.vehicles = action.payload;
  },
  getVehicle: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getVehicleSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.vehicle = action.payload;
  },
  createVehicle: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createVehicleSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.success = "Created Successfully";
  },
  updateVehicle: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  updateVehicleSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully";
  },
  resetVehicle: (state: ISettingState) => {
    state.vehicle = undefined;
  },
};
