import { ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const vehicleVariantActions = {
  getVehicleVariants: (state: ISettingState) => {
    state.loading = true;
  },
  getVehicleVariantsSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.vehicleVariants = action.payload;
  },
  getVehicleVariant: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getVehicleVariantSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.vehicleVariant = action.payload;
  },
  createVehicleVariant: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createVehicleVariantSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.success = "Created Successfully";
  },
  updateVehicleVariant: (state: ISettingState, action: PayloadAction<any>) => {
    return state;
  },
  updateVehicleVariantSuccess: (state: ISettingState) => {
    state.success = "Updated Successfully";
  },
  removeVehicleVariant: (state: ISettingState, action: PayloadAction<any>) => {
    return state;
  },
  removeVehicleVariantSuccess: (state: ISettingState) => {
    state.success = "Removed Successfully";
  },
  resetVehicleVariant: (state: ISettingState) => {
    state.vehicleVariant = undefined;
  },
};
