import axiosClient from "@/core/axios";
import { IResponse } from "@/shared/interfaces";

const routeConfigApi = {
  getRouteConfigs: (): Promise<IResponse<any>> => {
    const url = "/route-configs";
    return axiosClient.get(url);
  },
  getRouteConfigsWithRouteRules: (): Promise<IResponse<any>> => {
    const url = "/route-configs/with-route-rules";
    return axiosClient.get(url);
  },
  createRouteConfig: (data: any): Promise<IResponse<any>> => {
    const url = "/route-configs";
    return axiosClient.post(url, data);
  },
  getRouteConfig: (id: number): Promise<IResponse<any>> => {
    const url = `/route-configs/${id}`;
    return axiosClient.get(url);
  },
  updateRouteConfig: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/route-configs/${id}`;
    return axiosClient.patch(url, data);
  },
};

export default routeConfigApi;
