import {
  ICustomer,
  IDepot,
  IIntegrationSetup,
  IReason,
  IRouterConfiguration,
  IRouteRule,
  ITab,
  IUser,
  IVehicle,
  IVehicleVariant,
} from "@/shared/interfaces/setting";

const Tab: ITab = {
  list: "LIST",
  add: "ADD",
  save: "SAVE",
  view: "VIEW",
};

export const LIMIT_PAGE = 10;

export const DEFAULT_PAGE = 1;

export default {
  Tab: Tab,
};

export const DefaultValueWarehouses = {
  wareshouseIds: [],
};

export const DefaultValueReason: IReason = {
  name: "",
  deliveryEnabled: true,
  collectionEnabled: true,
  active: true,
};
export const DefaultValueRouterConfig: Omit<IRouterConfiguration, "id"> = {
  name: "",
  managedExternally: true,
  active: true,
  excludeFromDashboard: false,
  allowRemoteDispatch: true,
  gpsAutoStartRoute: true,
  activeSunday: true,
  cutoffTimeSunday: "00:00:00",
  dispatchTimeSunday: "00:00:00",
  dispatchSundayAddDays: 0,
  routeStartTimeSunday: "00:00:00",
  routeStartSundayAddDays: 0,
  activeMonday: true,
  cutoffTimeMonday: "00:00:00",
  dispatchTimeMonday: "00:00:00",
  dispatchMondayAddDays: 0,
  routeStartTimeMonday: "00:00:00",
  routeStartMondayAddDays: 0,
  activeTuesday: true,
  cutoffTimeTuesday: "00:00:00",
  dispatchTimeTuesday: "00:00:00",
  dispatchTuesdayAddDays: 0,
  routeStartTimeTuesday: "00:00:00",
  routeStartTuesdayAddDays: 0,
  activeWednesday: true,
  cutoffTimeWednesday: "00:00:00",
  dispatchTimeWednesday: "00:00:00",
  dispatchWednesdayAddDays: 0,
  routeStartTimeWednesday: "00:00:00",
  routeStartWednesdayAddDays: 0,
  activeThursday: true,
  cutoffTimeThursday: "00:00:00",
  dispatchTimeThursday: "00:00:00",
  dispatchThursdayAddDays: 0,
  routeStartTimeThursday: "00:00:00",
  routeStartThursdayAddDays: 0,
  activeFriday: true,
  cutoffTimeFriday: "00:00:00",
  dispatchTimeFriday: "00:00:00",
  dispatchFridayAddDays: 0,
  routeStartTimeFriday: "00:00:00",
  routeStartFridayAddDays: 0,
  activeSaturday: true,
  cutoffTimeSaturday: "00:00:00",
  dispatchTimeSaturday: "00:00:00",
  dispatchSaturdayAddDays: 0,
  routeStartTimeSaturday: "00:00:00",
  routeStartSaturdayAddDays: 0,
  vehicle: {
    id: 1,
  },
  vehicleVariant: {
    id: 0,
  },
  dispatchDepot: {
    id: 1,
  },
  optimisationPoints: [],
};

export const DefaultOptimationSetting = {
  name: "",
  longitude: "",
  latitude: "",
};

export const DefaultValueVehicle: Omit<IVehicle, "id"> = {
  description: "",
  registration: "",
  maxVolume: "",
  maxVolumeUnit: "m3",
  maxWeight: "",
  maxWeightUnit: "kg",
  externalTrackingId: "",
  notes: "",
  active: true,
  mobile: "",
  authCode: "",
};

export const DefaultValueVehicleVariant: Omit<IVehicleVariant, "id"> = {
  description: "",
  maxVolume: "",
  maxVolumeUnit: "m3",
  maxWeight: "",
  maxWeightUnit: "kg",
};

export const DefaultValueDepot: IDepot = {
  name: "",
  addressStreet: "",
  addressSuburb: "",
  addressCity: "",
  addressCountry: "",
  addressPostcode: "",
  latitude: "0", // TODO
  longitude: "0", // TODO
};

export const DefaultValueCustomer: ICustomer = {
  name: "",
  externalId: "",
  addresses: [],
  code: "",
  pricedPackingSlips: false,
};

export const DefaultValueRouteRule: IRouteRule = {
  name: "",
  active: true,
  isShowAttachedRule: false,
  isShowDestinationDepot: false,
  isShowRouteConfig: false,
};

export const DefaultValueUser: IUser = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  allowedDepotIDs: [],
  allowedDepotViewOrdersOnlyIDs: [],
  active: true,
  mobile: "",
};

export const VOLUMN_OPTIONS = [
  {
    value: "m3",
    label: "m3",
  },
];

export const WEIGHT_OPTIONS = [
  {
    value: "kg",
    label: "kg",
  },
];

export const DefaultValueIntegrationSetup: IIntegrationSetup = {
  name: "",
  active: true,
  keyMasked: "",
};
