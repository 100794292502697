import { ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const userActions = {
  getUsers: (state: ISettingState, action: PayloadAction<number>) => {
    state.loading = true;
  },
  getUsersSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    const { items, meta } = action.payload;
    state.users = items;
    state.totalPages = meta.totalPages;
  },
  getUser: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getUserSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.user = action.payload;
  },
  createUser: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createUserSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.success = "Created Successfully";
  },
  updateUser: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  updateUserSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully";
  },
  resetUser: (state: ISettingState) => {
    state.user = undefined;
  },
  deleteUser: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  deleteUserSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Removed Successfully";
  },
};
