import { all, fork } from "redux-saga/effects";
import authSaga from "@/screens/auth/authSaga";
import deliverySaga from "@/screens/delivery/deliverySaga";
import historySaga from "@/screens/history/historySaga";
import reportSaga from "@/screens/report/reportSaga";
import routeDetailsSaga from "@/screens/route-details/routeDetailsSaga";
import settingSaga from "@/screens/setting/settingSaga";

export default function* rootSaga() {
  yield all([fork(authSaga), fork(deliverySaga), fork(historySaga), fork(reportSaga), fork(routeDetailsSaga), fork(settingSaga)]);
}
