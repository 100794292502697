import TextField from "@mui/material/TextField";
import { Controller, FieldErrorsImpl } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ErrorIcon from "@mui/icons-material/Error";
interface IProps {
  name?: string;
  label?: string;
  value?: string | number;
  defaultValue?: string;
  placeholder?: string;
  inputClassName?: string;
  helperText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  size?: "small" | "medium" | undefined;
  type?: string;
  partInt?: boolean;
  id?: string;
  control?: any;
  rules?: any;
  autoFocus?: boolean;
  disabled?: boolean;
  errors?: Partial<FieldErrorsImpl<any>>;
}

const InputField = ({
  name = "",
  label,
  id,
  type = "text",
  size = "small",
  inputClassName = "",
  helperText,
  control,
  defaultValue = "",
  rules,
  errors,
  autoFocus = false,
  placeholder = "",
  disabled = false,
  partInt = true,
}: IProps) => {
  return (
    <div className="flex flex-col">
      {label && <label className="text-primary mb-3 font-semibold text-sm">{label}</label>}
      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={rules}
          render={({ field: { ref, onChange, ...field } }) => (
            <TextField
              id={id}
              disabled={disabled}
              size={size}
              placeholder={placeholder}
              type={type}
              autoFocus={autoFocus}
              InputProps={{
                style: {
                  fontWeight: 600,
                  fontSize: "14px",
                  borderRadius: "0",
                  background: "#FFFFFF",
                },
              }}
              onChange={(e) => onChange(type === "number" && partInt ? parseInt(e.target.value, 10) : e.target.value)}
              className={`text-sm w-full ${inputClassName}`}
              variant="outlined"
              {...field}
              inputRef={ref}
            />
          )}
        />
      </div>
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <p className="flex gap-2 items-center mt-2">
              <ErrorIcon sx={{ color: "red" }} />
              <label className="text-sm text-red-500">{message}</label>
            </p>
          )}
        />
      )}
      {helperText && <label className="text-xs mt-2">{helperText}</label>}
    </div>
  );
};

export default InputField;
