import DashboardRoute from "./dashboard/route";
//import ReportRoute from "./report/route"; //not required for phase 1
import DeliveryRoute from "./delivery/route";
import HistoryRoute from "./history/route";
import SettingRoute from "./setting/route";
import RouteDetailsRoute from "./route-details/route";
import Login from "./auth/Login/route";
import SetPassword from "./auth/SetPassword/route";
import ResetPasswordRequest from "./auth/ResetPasswordRequest/route";

const routes = [DashboardRoute, DeliveryRoute, HistoryRoute, SettingRoute, RouteDetailsRoute, Login, SetPassword, ResetPasswordRequest];

export default routes;
