import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeliveryRoute, RouteDetailsPayload } from "@/shared/interfaces";
import { IVehicle, IVehicleVariant } from "@/shared/interfaces/setting";

export interface RouteDetailsState {
  loading: boolean;
  route: DeliveryRoute;
  vehicles: IVehicle[];
  vehicleVariants: IVehicleVariant[];
}

const initialState: RouteDetailsState = {
  loading: false,
  route: {} as DeliveryRoute,
  vehicles: [],
  vehicleVariants: [],
};

const routeDetailsSlice = createSlice({
  name: "route-details",
  initialState,
  reducers: {
    reset: () => initialState,
    getRouteDetails: (state, action: PayloadAction<number>) => {
      state.loading = true;
    },
    getRouteDetailsSuccess: (state, action: PayloadAction<RouteDetailsPayload>) => {
      state.loading = false;
      state.vehicles = action.payload.vehicles;
      state.vehicleVariants = action.payload.vehicleVariants;
      state.route = action.payload.route;
    },
    addRouteStop: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    addRouteStopSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.loading = false;
      state.route = action.payload;
    },
    changeSequence: (state, action: PayloadAction<{ id: number; payload: any }>) => {
      state.loading = true;
    },
    changeSequenceSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.loading = false;
      state.route = action.payload;
    },
    updateRouteDetail: (state, action: PayloadAction<{ id: number; payload: any }>) => {
      state.loading = true;
    },
    updateRouteDetailSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.loading = false;
      state.route = action.payload;
    },
    getRouteStopsOptimise: (state, action: PayloadAction<number | string>) => {
      state.loading = true;
    },
    getRouteStopsOptimiseSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.route = action.payload;
    },
    confirmRoute: (state, action: PayloadAction<{ id: number; payload: any }>) => {
      state.loading = true;
    },
    confirmRouteSuccess: (state) => {
      state.loading = false;
    },
    disconnectRoute: (state, action: PayloadAction<{ deliveryId: number; routeId: number }>) => {
      state.loading = true;
    },
    disconnectRouteSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.loading = false;
      state.route = action.payload;
    },
    deleteRouteStop: (state, action: PayloadAction<{ stopId: number; routeId: number }>) => {
      state.loading = true;
    },
    deleteRouteStopSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.loading = false;
      state.route = action.payload;
    },
    requestFailed: (state) => {
      state.loading = false;
    },
  },
});

export const routeDetailsActions = routeDetailsSlice.actions;

export default routeDetailsSlice.reducer;
