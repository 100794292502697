import { RouteProps } from "react-router-dom";
import { routeConstants } from "@/shared/constants";

import Login from "./index";

const RouteDetailRoute: RouteProps = {
  path: routeConstants["login"].route,
  component: Login,
};

export default RouteDetailRoute;
