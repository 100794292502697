import { RouteProps } from "react-router-dom";
import { routeConstants } from "@/shared/constants";
import loadable from "@/shared/utils/loadable";

const HistoryRoute: RouteProps = {
  path: routeConstants.history.route,
  component: loadable(import("./index")),
};

export default HistoryRoute;
