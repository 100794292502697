import axiosClient from "@/core/axios";
import { DEFAULT_PAGE, LIMIT_PAGE } from "@/shared/constants/setting";
import { IResponse } from "@/shared/interfaces";

const customerApi = {
  getCustomers: (page = DEFAULT_PAGE): Promise<IResponse<any>> => {
    const url = `/customers/?page=${page}&limit=${LIMIT_PAGE}`;
    return axiosClient.get(url);
  },
  createCustomer: (data: any): Promise<IResponse<any>> => {
    const url = "/customers";
    return axiosClient.post(url, data);
  },
  updateCustomer: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/customers/${id}`;
    return axiosClient.patch(url, data);
  },
  getCustomer: (id: number): Promise<IResponse<any>> => {
    const url = `/customers/${id}`;
    return axiosClient.get(url);
  },
  removeCustomer: (id: number): Promise<IResponse<any>> => {
    const url = `/customers/${id}`;
    return axiosClient.delete(url);
  },
};

export default customerApi;
