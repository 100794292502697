import axiosClient from "@/core/axios";
import { DEFAULT_PAGE, LIMIT_PAGE } from "@/shared/constants/setting";
import { IResponse } from "@/shared/interfaces";

const userApi = {
  getUsers: (page = DEFAULT_PAGE): Promise<IResponse<any>> => {
    const url = `/users/?page=${page}&limit=${LIMIT_PAGE}`;
    return axiosClient.get(url);
  },
  getUser: (id: number): Promise<IResponse<any>> => {
    const url = `/users/${id}`;
    return axiosClient.get(url);
  },
  createUser: (data: any): Promise<IResponse<any>> => {
    const url = "/users/invite";
    return axiosClient.post(url, data);
  },
  updateUser: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/users/${id}`;
    return axiosClient.patch(url, data);
  },
  removeUser: (id: number): Promise<IResponse<any>> => {
    const url = `/users/${id}`;
    return axiosClient.delete(url);
  },
  getMyInfo: (): Promise<IResponse<any>> => {
    const url = `/users/me`;
    return axiosClient.get(url);
  },
};

export default userApi;
