export enum RouteStatus {
  PENDING = "Pending",
  OPEN = "Open",
  LOADING = "Loading",
  STARTED = "Started",
  CLOSED = "Closed",
  DISPATCHED = "Dispatched",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
}
