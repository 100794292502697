import { IReason, ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const reasonActions = {
  getReasons: (state: ISettingState) => {
    state.loading = true;
  },
  getReasonsSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.reasons = action.payload;
  },
  getReason: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getReasonSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.reason = action.payload;
  },
  createReason: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createReasonSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.success = "Created Successfully";
  },
  updateReason: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  updateReasonSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully";
  },
  updateReasonSequence: (state: ISettingState, action: PayloadAction<IReason[]>) => {
    state.loading = true;
  },
  updateReasonSequenceSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully";
  },
  removeReason: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  removeReasonSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Removed Successfully";
  },
};
