import { ERoute } from "../enums";
import { IRoute } from "../interfaces";

const routers: Record<ERoute, IRoute> = {
  [ERoute.DASHBOARD]: {
    name: "Dashboard",
    route: "/",
  },
  [ERoute.REPORT]: {
    name: "Report",
    route: "/reports",
  },
  [ERoute.DELIVERY]: {
    name: "Delivery",
    route: "/deliveries",
  },
  [ERoute.SETTING]: {
    name: "Setting",
    route: "/settings",
  },
  [ERoute.HISTORY]: {
    name: "History",
    route: "/histories",
  },
  [ERoute.ROUTE_DETAILS]: {
    name: "RouteDetails",
    route: "/route-details/:id",
  },
  [ERoute.LOGIN]: {
    name: "Login",
    route: "/login",
  },
  [ERoute.SET_PASSWORD]: {
    name: "SetPassword",
    route: "/set-password",
  },
  [ERoute.RESET_PASSWORD_REQUEST]: {
    name: "ResetPasswordRequest",
    route: "/reset-password-request",
  },
};

export default routers;
