import { useEffect, useState } from "react";
import { ReactComponent as ReactLogo } from "@/assets/logos/track-max.svg";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import InputField from "@/shared/components/inputs/InputField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Button, CircularProgress, Checkbox, Typography, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { authActions } from "../authSlice";
import { RootState } from "@/redux/store";
import { getRememberMe } from "@/core/token";
import { useHistory } from "react-router-dom";

interface IFormInput {
  authCode: string;
  username: string;
  password: string;
  remember: boolean;
}

const Login = () => {
  const rememberMe = getRememberMe() && JSON.parse(getRememberMe());

  const { control, handleSubmit, reset } = useForm<IFormInput>({
    defaultValues: {
      authCode: "",
      username: rememberMe.email,
      password: rememberMe.password,
      remember: !!rememberMe,
    },
  });
  const [isSendCode, setIsSendCode] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { authorizationToken, loading } = useAppSelector((state: RootState) => state.auth);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    const { username, password, remember, authCode } = data;
    if (authorizationToken && isSendCode) {
      dispatch(authActions.verifyAuthCode({ authCode: Number(authCode), authorizationToken: Number(authorizationToken) }));
      return;
    }
    dispatch(authActions.login({ email: username.toLowerCase(), password, remember }));
  };

  const back = () => {
    setIsSendCode(false);
    dispatch(authActions.reset());
  };

  const resetPassword = () => {
    setIsSendCode(false);
    history.push("/reset-password-request");
  };

  useEffect(() => {
    if (authorizationToken) {
      setIsSendCode(true);
      reset({
        username: "",
      });
    }
  }, [authorizationToken]);

  return (
    <Box className="w-[350px] sm:w-[385px] md:w-[510px] h-[580px] px-20 py-10 m-auto fixed top-1/2 left-1/2 right-1/2 bottom-1/2 -translate-x-1/2 border border-[#B6B6B6]">
      <Box className="relative w-full flex justify-center mb-10">
        <ReactLogo />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="relative flex flex-col gap-[15px] mb-8">
          {isSendCode && authorizationToken ? (
            <InputField
              label="Verify Code"
              name="authCode"
              size="medium"
              control={control}
              rules={{
                require: true,
              }}
            />
          ) : (
            <>
              <InputField
                label="Username"
                type="email"
                name="username"
                size="medium"
                control={control}
                rules={{
                  require: true,
                }}
              />
              <InputField
                label="Password"
                type="password"
                name="password"
                size="medium"
                control={control}
                rules={{
                  require: true,
                }}
              />
              <FormControlLabel
                control={
                  <Controller
                    name="remember"
                    control={control}
                    render={({ field }) => <Checkbox {...field} onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />}
                  />
                }
                label="Remember me"
              />
            </>
          )}
        </Box>
        <Box className="w-full flex justify-center mb-4">
          <Button type="submit" sx={{ borderRadius: "999px", width: "150px" }} className="h-14" color="primary" variant="contained">
            {loading ? <CircularProgress sx={{ color: "white" }} /> : isSendCode && authorizationToken ? "VERIFY" : "LOG IN"}
          </Button>
        </Box>
        {isSendCode && authorizationToken && (
          <Typography className="text-center text-base">
            Back to login? <Button onClick={back}>Login</Button>
          </Typography>
        )}
        {!isSendCode && !authorizationToken && (
          <Typography className="text-center pb-2">
            <Button onClick={resetPassword}>Forgot Password?</Button>
          </Typography>
        )}
      </form>
    </Box>
  );
};

export default Login;
