import axiosClient from "@/core/axios";
import { DEFAULT_PAGE } from "@/shared/constants/setting";
import { IResponse } from "@/shared/interfaces";

const routeRuleApi = {
  getRouteRules: (page = DEFAULT_PAGE): Promise<IResponse<any>> => {
    const url = `/route-rules/?page=${page}&limit=200`;
    return axiosClient.get(url);
  },
  getRouteRule: (id: number): Promise<IResponse<any>> => {
    const url = `/route-rules/${id}`;
    return axiosClient.get(url);
  },
  createRouteRule: (data: any): Promise<IResponse<any>> => {
    const url = "/route-rules";
    return axiosClient.post(url, data);
  },
  updateRouteRule: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/route-rules/${id}`;
    return axiosClient.patch(url, data);
  },
};

export default routeRuleApi;
