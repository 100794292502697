import { RouteProps } from "react-router-dom";
import { routeConstants } from "@/shared/constants";

import ResetPasswordRequest from "./index";

const ResetPasswordRequestRoute: RouteProps = {
  path: routeConstants["reset-password-request"].route,
  component: ResetPasswordRequest,
};

export default ResetPasswordRequestRoute;
