import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeliveryRoute, RoutesQueryParams, RouteResponse, ERPOrdersQueryParams, DeliveryAttempt } from "@/shared/interfaces";
import { Routes } from "@/shared/interfaces/route";

export interface DeliveryState {
  loading: boolean;
  orderLoading: boolean;
  needActionLoading: boolean;
  routes: DeliveryRoute[];
  route: DeliveryRoute;
  deliveryAttempts: DeliveryAttempt[];
  deliveryOrderDetail: DeliveryAttempt;
  orders: any[];
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  warehousesSelected: number[];
  currentRoutesOnly: boolean;
}

export type DropConnectRoutePayload = { dataPayload: { ids: string[]; routeId: number }; params: RoutesQueryParams };

const initialState: DeliveryState = {
  loading: false,
  orderLoading: false,
  needActionLoading: false,
  routes: [],
  route: {} as DeliveryRoute,
  deliveryAttempts: [],
  deliveryOrderDetail: {} as DeliveryAttempt,
  orders: [],
  currentPage: 1,
  itemsPerPage: 3000,
  totalPages: 1,
  warehousesSelected: [],
  currentRoutesOnly: true,
};

const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    getRoutes: (state, action: PayloadAction<RoutesQueryParams>) => {
      // Setting to true will only cause a blinking effect
      state.loading = false;
      state.warehousesSelected = action.payload.warehouseIds ?? [];
      state.currentRoutesOnly = action.payload?.currentRoutesOnly ?? state.currentRoutesOnly;
    },
    getRoutesSuccess: (state, action: PayloadAction<RouteResponse>) => {
      state.loading = false;
      state.routes = action.payload.items;
      state.currentPage = action.payload.meta.currentPage;
      state.itemsPerPage = action.payload.meta.itemsPerPage;
      state.totalPages = action.payload.meta.totalPages;
    },
    setRoutes: (state, action: PayloadAction<Routes>) => {
      state.routes = action.payload.routes;
    },
    getRoute: (state, action: PayloadAction<number>) => {
      // Setting to true will only cause a blinking effect
      state.orderLoading = false;
    },
    getRouteSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.orderLoading = false;
      state.route = action.payload;
    },
    refreshRoute: (state, action: PayloadAction<number>) => {
      // Setting to true will only cause a blinking effect
      state.orderLoading = false;
    },
    refreshRouteSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.orderLoading = false;
    },
    getDeliveryAttempts: (state) => {
      state.needActionLoading = true;
    },
    getDeliveryAttemptsSuccess: (state, action: PayloadAction<DeliveryAttempt[]>) => {
      state.needActionLoading = false;
      state.deliveryAttempts = action.payload;
    },
    getDeliveryAttempt: (state, action: PayloadAction<number>) => {
      state.needActionLoading = true;
    },
    getDeliveryAttemptSuccess: (state, action: PayloadAction<DeliveryAttempt>) => {
      state.needActionLoading = false;
      state.deliveryOrderDetail = action.payload;
    },
    getOrders: (state, action: PayloadAction<ERPOrdersQueryParams>) => {
      state.orderLoading = true;
    },
    getOrdersSuccess: (state, action: PayloadAction<any>) => {
      state.orderLoading = false;
      state.orders = action.payload;
    },
    getOrdersToAppend: (state, action: PayloadAction<ERPOrdersQueryParams>) => {
      state.orderLoading = false;
    },
    getOrdersToAppendSuccess: (state, action: PayloadAction<any>) => {
      if (state.orders.length) {
        state.orders = [...state.orders, ...action.payload];
      }
    },
    openRoute: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    openRouteSuccess: (state, action: PayloadAction<RouteResponse>) => {
      state.loading = false;
      state.routes = action.payload.items;
    },
    disconnectRoute: (state, action: PayloadAction<{ deliveryId: number; routeId: number }>) => {
      state.loading = true;
    },
    disconnectRouteSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.loading = false;
      state.route = action.payload;
    },
    deleteRoute: (state, action: PayloadAction<{ id: number; params: RoutesQueryParams }>) => {
      state.loading = true;
    },
    deleteRouteSuccess: (state, action: PayloadAction<RouteResponse>) => {
      state.loading = false;
      state.routes = action.payload.items;
      state.currentPage = action.payload.meta.currentPage;
      state.itemsPerPage = action.payload.meta.itemsPerPage;
      state.totalPages = action.payload.meta.totalPages;
    },
    requestFailed: (state) => {
      state.loading = false;
      state.orderLoading = false;
    },
    recalculateRoute: (state, action: PayloadAction<{ routeId: number }>) => {
      // Setting to true will only cause a blinking effect
      state.orderLoading = false;
    },
    recalculateRouteSuccess: (state, action: PayloadAction<DeliveryRoute>) => {
      state.orderLoading = false;
      state.route = action.payload;
    },
  },
});

export const deliveryActions = deliverySlice.actions;

export default deliverySlice.reducer;
