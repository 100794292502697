import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "@/screens/auth/authSlice";
import deliveryReducer from "@/screens/delivery/deliverySlice";
import historyReducer from "@/screens/history/historySlice";
import reportReducer from "@/screens/report/reportSlice";
import routeDetailsReducer from "@/screens/route-details/routeDetailsSlice";
import settingReducer from "@/screens/setting/settingSlice";

export const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    delivery: deliveryReducer,
    history: historyReducer,
    report: reportReducer,
    routeDetails: routeDetailsReducer,
    settings: settingReducer,
  });
