import reportApi from "@/api/report";
import { toError } from "@/shared/utils/toError";
import { call, takeEvery, put } from "redux-saga/effects";
import { reportActions } from "./reportSlice";

export function* handleRequestCustomerReports() {
  try {
    const { data } = yield call(reportApi.getCustomerReport);
    yield put(reportActions.getCustomerReportsSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(reportActions.getCustomerReportsFailed(err));
  }
}

export function* handleRequestDeliveryReports() {
  try {
    const { data } = yield call(reportApi.getDeliveryHistory);
    yield put(reportActions.getDeliveryReportsSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(reportActions.getCustomerReportsFailed(err));
  }
}

export default function* watchReportSaga() {
  yield takeEvery(reportActions.getCustomerReports.type, handleRequestCustomerReports);
  yield takeEvery(reportActions.getDeliveryReports.type, handleRequestDeliveryReports);
}
