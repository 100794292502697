const themeConfig = {
  color: {
    primary: "#FD6B24",
    primaryLight: "#D1DBC3",
    secondary: "#B6B6B6",
    success: "#66CC4D",
    warning: "#FFA319",
    error: "#be0627",
    info: "#33C2FF",
    black: "#121212",
    white: "#ffffff",
    primaryAlt: "#000C57",
    gray1: "#B6B6B6",
    gray2: "#E7E7E7",
    gray3: "#CBCBCB",
    gray4: "#D9D9D9",
    gray7: "#828282",
    gray8: "#4F4F4F",
    gray9: "#333333",
    gray10: "#FCFCFC",
    gray11: "#adb5bd",
  },
  fontFamily: {
    refrigerator: ["Refrigerator Deluxe W01 Xbold", "sans-serif"],
  },
};

module.exports = themeConfig;
