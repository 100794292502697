import { RouteProps } from "react-router-dom";
import { routeConstants } from "../../shared/constants";
import loadable from "../../shared/utils/loadable";

const DashboardRoute: RouteProps = {
  path: routeConstants.dashboard.route,
  component: loadable(import("./index")),
};

export default DashboardRoute;
