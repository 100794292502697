import axiosClient from "@/core/axios";
import { IResponse } from "@/shared/interfaces";

const reportApi = {
  getCustomerReport: (): Promise<IResponse<any>> => {
    const url = "/reports/customer-difot";
    return axiosClient.get(url);
  },
  getDeliveryHistory: (): Promise<IResponse<any>> => {
    const url = "/reports/delivery-history";
    return axiosClient.get(url);
  },
};

export default reportApi;
