export const SESSION_TOKEN = "sessionToken";
export const ACCESS_TOKEN = "accessToken";
export const USER_ROLE = "userRole";
export const REMEMBER_ME = "remember_me";

export const setToken = (token: string) => sessionStorage.setItem(SESSION_TOKEN, token);
export const getToken = () => sessionStorage.getItem(SESSION_TOKEN) ?? "";
export const removeToken = () => sessionStorage.removeItem(SESSION_TOKEN);

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN) ?? "";
export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN);
export const setAccessToken = (accessToken: string) => localStorage.setItem(ACCESS_TOKEN, accessToken);
export const setRole = (role: string) => localStorage.setItem(USER_ROLE, role);
export const getRole = () => localStorage.getItem(USER_ROLE) ?? "";

export const setRememberMe = (email: string) => localStorage.setItem(REMEMBER_ME, email);
export const getRememberMe = () => localStorage.getItem(REMEMBER_ME) ?? "";
export const removeRememberMe = () => localStorage.removeItem(REMEMBER_ME);
