import axiosClient from "@/core/axios";
import { IResponse } from "@/shared/interfaces";

const reasonApi = {
  getReasons: (): Promise<IResponse<any>> => {
    const url = "/reject-reasons";
    return axiosClient.get(url);
  },
  createReason: (data: any): Promise<IResponse<any>> => {
    const url = "/reject-reasons";
    return axiosClient.post(url, data);
  },
  getReason: (id: number): Promise<IResponse<any>> => {
    const url = `/reject-reasons/${id}`;
    return axiosClient.get(url);
  },
  updateReason: (id: number, data: any): Promise<IResponse<any>> => {
    const url = `/reject-reasons/${id}`;
    return axiosClient.patch(url, data);
  },
  updateReasonSequence: (data: any): Promise<IResponse<any>> => {
    const url = "/reject-reasons/sequence";
    return axiosClient.post(url, data);
  },
  removeReason: (id: number): Promise<IResponse<any>> => {
    const url = `/reject-reasons/${id}`;
    return axiosClient.delete(url);
  },
};

export default reasonApi;
