import { createTheme } from "@mui/material";
const themeConfig = require("../configs/theme");

const theme = createTheme({
  palette: {
    primary: {
      main: themeConfig.color.primary,
    },
    secondary: {
      main: themeConfig.color.secondary,
    },
    success: {
      main: themeConfig.color.success,
    },
  },
  typography: {
    h4: {
      fontSize: "25px",
    },
    h5: {
      fontSize: "24px",
      fontFamily: "Refrigerator Deluxe W01 Bold",
    },
    body1: {
      fontSize: "14px",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "160px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundColor: themeConfig.color.white,
          borderBottom: `1px solid ${themeConfig.color.gray2}`,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "0",
        },
        sizeMedium: {
          fontSize: "13px",
          fontWeight: "bold",
        },
        sizeSmall: {
          fontSize: "11px",
          fontWeight: "bold",
        },
        containedPrimary: {
          color: themeConfig.color.white,
        },
        outlinedSecondary: {
          color: "#717171",
        },
      },
    },
  },
});

export default theme;
