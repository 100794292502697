import { ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const routeRuleActions = {
  getRouteRules: (state: ISettingState, action: PayloadAction<number>) => {
    state.loading = true;
  },
  getRouteRulesSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    const { items, meta } = action.payload;
    state.routeRules = items;
    state.totalPages = meta.totalPages;
  },
  getRouteRule: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getRouteRuleSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.routeRule = action.payload;
  },
  createRouteRule: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createRouteRuleSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.success = "Created Successfully";
  },
  updateRouteRule: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  updateRouteRuleSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully";
  },
  resetRouteRule: (state: ISettingState) => {
    state.routeRule = undefined;
  },
};
