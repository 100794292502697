import { ReactComponent as ReactLogo } from "@/assets/logos/track-max.svg";
import { useForm, SubmitHandler } from "react-hook-form";
import InputField from "@/shared/components/inputs/InputField";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { RootState } from "@/redux/store";
import { toast } from "react-toastify";
import { authActions } from "../authSlice";

export interface IFormInput {
  email: string;
}

const ResetPasswordRequest = () => {
  const { control, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      email: "",
    },
  });

  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state: RootState) => state.auth);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const { email } = data;
    if (!email.trim().length) {
      toast.error("Please enter valid email.");
      return;
    }
    dispatch(authActions.resetPasswordRequest({ email }));
  };

  return (
    <Box className="w-[350px] sm:w-[385px] md:w-[510px] h-[550px] px-20 py-10 m-auto fixed top-1/2 left-1/2 right-1/2 bottom-1/2 -translate-x-1/2 border border-[#B6B6B6]">
      <Box className="w-full flex justify-center mb-10">
        <ReactLogo />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="flex flex-col gap-[15px] mb-8">
          <Typography className="!text-[20px] text-center">Reset Password</Typography>
          <>
            <InputField
              label="Email"
              type="email"
              name="email"
              size="medium"
              control={control}
              rules={{
                require: true,
              }}
            />
          </>
        </Box>
        <Box className="w-full flex justify-center mb-4">
          <Button type="submit" sx={{ borderRadius: "999px", width: "150px" }} className="h-14" color="primary" variant="contained">
            {loading ? <CircularProgress sx={{ color: "white" }} /> : "CONFIRM"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ResetPasswordRequest;
