import { RouteProps } from "react-router-dom";
import { routeConstants } from "@/shared/constants";

import SetPassword from "./index";

const SetPasswordRoute: RouteProps = {
  path: routeConstants["set-password"].route,
  component: SetPassword,
};

export default SetPasswordRoute;
