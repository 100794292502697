import axiosClient from "@/core/axios";
import { IResponse, OrdersQueryParams, OrderDetail, OrdersResponse, ERPOrdersQueryParams, OrderItem } from "@/shared/interfaces";

const orderApi = {
  getOrders: (params?: OrdersQueryParams): Promise<IResponse<OrderItem>> => {
    const url = "/orders";
    return axiosClient.get(url, { params });
  },
  getOneOrder: (id: number): Promise<IResponse<OrderDetail>> => {
    const url = `/orders/${id}`;
    return axiosClient.get(url);
  },
  assignAllOrder: ({ orderId, routeId }: { orderId: number; routeId: number }) => {
    const url = `orders/${orderId}/assign-route/${routeId}`;
    return axiosClient.patch(url);
  },
  getDeliveryDocketForOrderPdf: (orderId: number): Promise<IResponse<any>> => {
    const url = `orders/generate-delivery-docket-for-order/${orderId}`;
    return axiosClient.get(url);
  },
};

export default orderApi;
