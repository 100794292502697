import { ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const customerActions = {
  getCustomers: (state: ISettingState, action: PayloadAction<number>) => {
    state.loading = true;
  },
  getCustomersSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    const { items, meta } = action.payload;
    state.customers = items;
    state.totalPages = meta.totalPages;
  },
  getCustomer: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getCustomerSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.customer = action.payload;
  },
  createCustomer: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createCustomerSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.success = "Created Successfully";
  },
  updateCustomer: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  updateCustomerSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully";
  },
  removeCustomer: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  removeCustomerSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Removed Successfully";
  },
  resetCustomer: (state: ISettingState) => {
    state.customer = undefined;
  },
};
