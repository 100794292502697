import { useEffect, useState } from "react";
import { ReactComponent as ReactLogo } from "@/assets/logos/track-max.svg";
import { useForm, SubmitHandler } from "react-hook-form";
import InputField from "@/shared/components/inputs/InputField";
import { Box, Button, CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { authActions } from "../authSlice";
import { RootState } from "@/redux/store";
import { toast } from "react-toastify";

export interface IFormInput {
  password: string;
  confirm_password: string;
}

const SetPassword = () => {
  const { control, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });
  const [authorizationToken, setAuthorizationToken] = useState("");

  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state: RootState) => state.auth);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const { password, confirm_password } = data;
    if (!password.trim().length) {
      toast.error("Please enter valid password.");
      return;
    }
    if (password !== confirm_password) {
      toast.error("Passwords doesn't match.");
      return;
    }

    if (authorizationToken && typeof authorizationToken === "string") {
      dispatch(authActions.setNewResetPassword({ password, token: authorizationToken }));
    } else {
      dispatch(authActions.setNewPassword({ password, authorizationToken: Number(authorizationToken) }));
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code") || "";
    setAuthorizationToken(code);
  }, [window?.location]);

  return (
    <Box className="w-[350px] sm:w-[385px] md:w-[510px] h-[550px] px-20 py-10 m-auto fixed top-1/2 left-1/2 right-1/2 bottom-1/2 -translate-x-1/2 border border-[#B6B6B6]">
      <Box className="w-full flex justify-center mb-10">
        <ReactLogo />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="flex flex-col gap-[15px] mb-8">
          <>
            <InputField
              label="Password"
              type="password"
              name="password"
              size="medium"
              control={control}
              rules={{
                require: true,
              }}
            />
            <InputField
              label="Confirm Password"
              type="password"
              name="confirm_password"
              size="medium"
              control={control}
              rules={{
                require: true,
              }}
            />
          </>
        </Box>
        <Box className="w-full flex justify-center mb-4">
          <Button type="submit" sx={{ borderRadius: "999px", width: "150px" }} className="h-14" color="primary" variant="contained">
            {loading ? <CircularProgress sx={{ color: "white" }} /> : "CONFIRM"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default SetPassword;
