enum ERoute {
  REPORT = "report",
  DASHBOARD = "dashboard",
  DELIVERY = "delivery",
  HISTORY = "history",
  SETTING = "setting",
  ROUTE_DETAILS = "route-details",
  LOGIN = "login",
  SET_PASSWORD = "set-password",
  RESET_PASSWORD_REQUEST = "reset-password-request",
}

export default ERoute;
