import { PayloadAction } from "@reduxjs/toolkit";
import { put, call, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { setAccessToken, setRememberMe, removeRememberMe, getRememberMe, setRole } from "@/core/token";
import { authActions } from "./authSlice";
import authApi from "@/api/auth";
import { AuthCodePayload, LoginPayload, ResetPasswordPayload, SetNewPasswordPasswordPayload } from "@/shared/interfaces";
import { toError } from "@/shared/utils/toError";
import userApi from "@/api/setting/user";
import { ResetPasswordRequestPayload, SetNewResetPasswordPayload } from "@/shared/interfaces/auth";

function handleRemember(user: { email: string; password: string }, remember?: boolean) {
  if (!remember) {
    const existRemember = getRememberMe();
    if (!existRemember) return;
    removeRememberMe();
    return;
  }
  setRememberMe(JSON.stringify(user));
}

function* handleLogin(action: PayloadAction<LoginPayload>) {
  const { email, password, remember } = action.payload;
  try {
    const { data } = yield call(authApi.login, { email, password });
    if (!data.authorizationToken) {
      yield put(authActions.reset());
      throw Error("Wrong Password");
    }
    handleRemember({ email, password }, remember);
    yield put(authActions.loginSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(authActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleVerifyAuthCode(action: PayloadAction<AuthCodePayload>) {
  const { authorizationToken, authCode } = action.payload;
  try {
    const { data } = yield call(authApi.verifyCode, { authCode, authorizationToken });
    if (!data.accessToken) {
      yield put(authActions.requestFailed());
      throw Error("Login Failed");
    }
    setAccessToken(data.accessToken);

    const { data: result } = yield call(userApi.getMyInfo);
    // yield put(authActions.getMyselfSuccess(result));
    setRole(result.role);

    yield put(authActions.verifyAuthCodeSuccess());
    //redirect after Verify success
    yield put(push("/deliveries"));
  } catch (error) {
    const err = toError(error);
    yield put(authActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleSetNewPassword(action: PayloadAction<SetNewPasswordPasswordPayload>) {
  const { authorizationToken, password } = action.payload;
  try {
    const { data } = yield call(authApi.setNewPassword, { password, authorizationToken });
    if (!data.accessToken) {
      yield put(authActions.requestFailed());
      throw Error("Set Password Failed");
    }
    setAccessToken(data.accessToken);
    yield put(authActions.setNewPasswordSuccess());
    //redirect after Set New Password success
    yield put(push("/deliveries"));
  } catch (error) {
    const err = toError(error);
    yield put(authActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleResetPassword(action: PayloadAction<ResetPasswordPayload>) {
  const { userId, password } = action.payload;
  try {
    const { data } = yield call(authApi.resetPassword, { password, userId });
    if (data.statusCode !== 200) {
      yield put(authActions.requestFailed());
      throw Error("Reset Password Failed");
    }
    yield put(authActions.resetPasswordSuccess());
    toast.success("Reset Password Success");
  } catch (error) {
    const err = toError(error);
    yield put(authActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleSetNewResetPassword(action: PayloadAction<SetNewResetPasswordPayload>) {
  const { token, password } = action.payload;
  try {
    yield call(authApi.setNewResetPassword, { password, token });
    yield put(authActions.setNewResetPasswordSuccess());
    //redirect after Set New Password success
    toast.success("Password has been reset");
    yield put(push("/login"));
  } catch (error) {
    const err = toError(error);
    yield put(authActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleResetPasswordRequest(action: PayloadAction<ResetPasswordRequestPayload>) {
  const { email } = action.payload;
  try {
    const { data } = yield call(authApi.resetPasswordRequest, { email });
    if (data.statusCode !== 200) {
      yield put(authActions.requestFailed());
      throw Error("Please check your details");
    }
    yield put(authActions.resetPasswordRequestSuccess());
    toast.success("Reset link has been sent to your email");
    yield put(push("/login"));
  } catch (error) {
    const err = toError(error);
    yield put(authActions.requestFailed());
    toast.error(err.message);
  }
}

export default function* watchAuthSaga() {
  yield takeLatest(authActions.login.type, handleLogin);
  yield takeLatest(authActions.verifyAuthCode.type, handleVerifyAuthCode);
  yield takeLatest(authActions.setNewPassword.type, handleSetNewPassword);
  yield takeLatest(authActions.resetPassword.type, handleResetPassword);
  yield takeLatest(authActions.resetPasswordRequest.type, handleResetPasswordRequest);
  yield takeLatest(authActions.setNewResetPassword.type, handleSetNewResetPassword);
}
