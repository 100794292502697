import { RouteProps } from "react-router-dom";
import { routeConstants } from "@/shared/constants";
import loadable from "@/shared/utils/loadable";

const RouteDetailRoute: RouteProps = {
  path: routeConstants["route-details"].route,
  component: loadable(import("./index")),
};

export default RouteDetailRoute;
