import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import routeApi from "@/api/route";
import orderApi from "@/api/order";
import deliveryAttemptsApi from "@/api/deliveryAttempts";
import { toError } from "@/shared/utils/toError";
import { deliveryActions } from "./deliverySlice";
import { ERPOrdersQueryParams, RoutesQueryParams } from "@/shared/interfaces";
import { IRouterConfiguration } from "@/shared/interfaces/setting";

function* handleGetRoutes(action: PayloadAction<RoutesQueryParams>) {
  const queryParams = action.payload;
  try {
    const { data } = yield call(routeApi.getRoutes, queryParams);
    yield put(deliveryActions.getRoutesSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleGetRouteDetail(action: PayloadAction<number>) {
  const id = action.payload;
  try {
    const { data } = yield call(routeApi.getRoute, id);
    yield put(deliveryActions.getRouteSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleRefreshRouteDetail(action: PayloadAction<number>) {
  const id = action.payload;
  try {
    const { data } = yield call(routeApi.refreshRoute, id);
    yield put(deliveryActions.getRouteSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleDisconnectRoute(action: PayloadAction<{ deliveryId: number; routeId: number }>) {
  const { deliveryId, routeId } = action.payload;
  try {
    yield call(deliveryAttemptsApi.disconnectRoute, deliveryId);
    const { data } = yield call(routeApi.getRoute, routeId);
    yield put(deliveryActions.disconnectRouteSuccess(data));
    yield call(routeApi.recalculateRoute, routeId);
    yield put(deliveryActions.getRoutes({ open: true, includeDispatched: false, page: 1, limit: 3000 }));
    toast.success("Disconnected");
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleRecalculateRoute(action: PayloadAction<{ routeId: number }>) {
  const { routeId } = action.payload;
  try {
    yield call(routeApi.recalculateRoute, routeId);
    const { data } = yield call(routeApi.getRoute, routeId);
    yield put(deliveryActions.recalculateRouteSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleGetNeedActionDelivery() {
  try {
    const { data } = yield call(deliveryAttemptsApi.getDeliveryNeedAction);
    yield put(deliveryActions.getDeliveryAttemptsSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleGetOrders(action: PayloadAction<ERPOrdersQueryParams>) {
  const queryParams = action.payload;
  try {
    const { data } = yield call(orderApi.getOrders, queryParams);
    yield put(deliveryActions.getOrdersSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleGetOrdersToPrepend(action: PayloadAction<ERPOrdersQueryParams>) {
  const queryParams = action.payload;
  try {
    const { data } = yield call(orderApi.getOrders, queryParams);
    yield put(deliveryActions.getOrdersToAppendSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleOpenNewRoute(action: PayloadAction<{ params: RoutesQueryParams; payload: { routeConfig: IRouterConfiguration } }>) {
  const { params, payload } = action.payload;
  try {
    yield call(routeApi.createRoute, payload);
    const { data } = yield call(routeApi.getRoutes, params);
    yield put(deliveryActions.openRouteSuccess(data));
    toast.success("Opened New Route");
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleDeleteRoute(action: PayloadAction<{ id: number; params: RoutesQueryParams }>) {
  const { id, params } = action.payload;
  try {
    yield call(routeApi.deleteRoute, id);
    const { data } = yield call(routeApi.getRoutes, params);
    yield put(deliveryActions.deleteRouteSuccess(data));
    toast.success("Route Deleted");
  } catch (error) {
    const err = toError(error);
    yield put(deliveryActions.requestFailed());
    toast.error(err.message);
  }
}

export default function* watchDeliverySaga() {
  yield takeEvery(deliveryActions.getRoutes.type, handleGetRoutes);
  yield takeLatest(deliveryActions.getRoute.type, handleGetRouteDetail);
  yield takeLatest(deliveryActions.refreshRoute.type, handleRefreshRouteDetail);
  yield takeEvery(deliveryActions.getDeliveryAttempts.type, handleGetNeedActionDelivery);
  yield takeEvery(deliveryActions.getOrders.type, handleGetOrders);
  yield takeEvery(deliveryActions.getOrdersToAppend.type, handleGetOrdersToPrepend);
  yield takeLatest(deliveryActions.openRoute.type, handleOpenNewRoute);
  yield takeLatest(deliveryActions.deleteRoute.type, handleDeleteRoute);
  yield takeLatest(deliveryActions.disconnectRoute.type, handleDisconnectRoute);
  yield takeLatest(deliveryActions.recalculateRoute.type, handleRecalculateRoute);
}
