import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeliveryRoute, RoutesQueryParams, RouteResponse, OrderItem, OrdersQueryParams } from "@/shared/interfaces";

export interface HistoryState {
  loading: boolean;
  routes: DeliveryRoute[];
  orders: OrderItem[];
  order: Record<string, any>;
  currentPage: number;
  itemsPerPage: number;
  totalPages: number;
  warehousesSelected: number[];
}

const initialState: HistoryState = {
  loading: false,
  routes: [],
  orders: [],
  order: {},
  currentPage: 1,
  itemsPerPage: 3000,
  totalPages: 1,
  warehousesSelected: [],
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    reset: () => initialState,
    getRoutes: (state, action: PayloadAction<RoutesQueryParams>) => {
      state.loading = true;
      state.warehousesSelected = action.payload.warehouseIds ?? [];
    },
    getRoutesSuccess: (state, action: PayloadAction<RouteResponse>) => {
      state.loading = false;
      state.routes = action.payload.items;
      state.currentPage = action.payload.meta.currentPage;
      state.itemsPerPage = action.payload.meta.itemsPerPage;
      state.totalPages = action.payload.meta.totalPages;
    },
    getOrders: (state, action: PayloadAction<OrdersQueryParams>) => {
      state.loading = true;
    },
    getOrdersSuccess: (state, action: PayloadAction<OrderItem[]>) => {
      state.loading = false;
      state.orders = action.payload;
    },
    deleteRoute: (state, action: PayloadAction<{ id: number; params: RoutesQueryParams }>) => {
      state.loading = true;
    },
    deleteRouteSuccess: (state, action: PayloadAction<RouteResponse>) => {
      state.loading = false;
      state.routes = action.payload.items;
    },
    removeRouteFromPOD: (state, action: PayloadAction<{ id: number; params: RoutesQueryParams }>) => {
      state.loading = true;
    },
    removeRouteFromPODSuccess: (state, action: PayloadAction<RouteResponse>) => {
      state.loading = false;
      state.routes = action.payload.items;
    },
    requestFailed: (state) => {
      state.loading = false;
    },
  },
});

export const historyActions = historySlice.actions;

export default historySlice.reducer;
