import { call, takeEvery, put, takeLatest, all } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import routeApi from "@/api/route";
import routeStopApi from "@/api/routeStop";
import vehicleVariantApi from "@/api/vehicleVariant";
import { AddRouteStopPayload, DeliveryRoute, IResponse } from "@/shared/interfaces";
import { toError } from "@/shared/utils/toError";
import { routeDetailsActions } from "./routeDetailsSlice";
import deliveryAttemptsApi from "@/api/deliveryAttempts";

function* handleRequestRouteDetails(action: PayloadAction<number>) {
  const id = action.payload;
  try {
    const [route, vehicles, vehicleVariants]: any[] = yield all([
      call(routeApi.getRoute, id),
      call(routeApi.getRouteVehicles, id),
      call(vehicleVariantApi.getVehicleVariants),
    ]);
    const data = {
      route: route.data,
      vehicles: vehicles.data,
      vehicleVariants: vehicleVariants.data,
    };
    yield put(routeDetailsActions.getRouteDetailsSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(routeDetailsActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleRequestRouteStopsOptimise(action: PayloadAction<number>) {
  const id = action.payload;
  try {
    const { data } = yield call(routeApi.getRouteOptimise, id);
    yield put(routeDetailsActions.getRouteStopsOptimiseSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(routeDetailsActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleAddRouteStop(action: PayloadAction<AddRouteStopPayload>) {
  const dataPayload = action.payload;
  try {
    yield call(routeStopApi.createRouteStop, dataPayload);
    const { data } = yield call(routeApi.getRoute, dataPayload.route.id);
    yield put(routeDetailsActions.addRouteStopSuccess(data));
    toast.success("Added Successfully");
  } catch (error) {
    const err = toError(error);
    yield put(routeDetailsActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleChangeSequence(action: PayloadAction<{ id: number; payload: DeliveryRoute }>) {
  const { id, payload } = action.payload;
  try {
    const { data } = yield call(routeApi.changeSequence, { id, payload });
    yield put(routeDetailsActions.changeSequenceSuccess(data));
  } catch (error: any) {
    yield put(routeDetailsActions.requestFailed());
    toast.error(error?.response?.data?.message ?? error.message);
  }
}

function* handleUpdateRoute(action: PayloadAction<{ id: number; payload: DeliveryRoute }>) {
  const { id, payload } = action.payload;
  try {
    const { data } = yield call(routeApi.updateRoute, { id, payload });
    yield put(routeDetailsActions.updateRouteDetailSuccess(data));
  } catch (error) {
    const err = toError(error);
    yield put(routeDetailsActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleDisconnectRoute(action: PayloadAction<{ deliveryId: number; routeId: number }>) {
  const { deliveryId, routeId } = action.payload;
  try {
    yield call(deliveryAttemptsApi.disconnectRoute, deliveryId);
    const { data } = yield call(routeApi.getRoute, routeId);
    yield put(routeDetailsActions.disconnectRouteSuccess(data));
    toast.success("Disconnected");
  } catch (error) {
    const err = toError(error);
    yield put(routeDetailsActions.requestFailed());
    toast.error(err.message);
  }
}

function* handleDeleteRouteStop(action: PayloadAction<{ stopId: number; routeId: number }>) {
  const { stopId, routeId } = action.payload;
  try {
    yield call(routeStopApi.deleteRouteStop, stopId);
    const { data } = yield call(routeApi.getRoute, routeId);
    yield put(routeDetailsActions.deleteRouteStopSuccess(data));
    toast.success("Disconnected");
  } catch (error) {
    const err = toError(error);
    yield put(routeDetailsActions.requestFailed());
    toast.error(err.message);
  }
}

export default function* watchReportSaga() {
  yield takeEvery(routeDetailsActions.getRouteDetails.type, handleRequestRouteDetails);
  yield takeLatest(routeDetailsActions.addRouteStop.type, handleAddRouteStop);
  yield takeLatest(routeDetailsActions.getRouteStopsOptimise.type, handleRequestRouteStopsOptimise);
  yield takeLatest(routeDetailsActions.updateRouteDetail.type, handleUpdateRoute);
  yield takeLatest(routeDetailsActions.changeSequence.type, handleChangeSequence);
  yield takeLatest(routeDetailsActions.disconnectRoute.type, handleDisconnectRoute);
  yield takeLatest(routeDetailsActions.deleteRouteStop.type, handleDeleteRouteStop);
}
