import axiosClient from "@/core/axios";
import {
  LoginPayload,
  AuthCodePayload,
  IResponse,
  LoginResponse,
  AuthCodeResponse,
  SetNewPasswordPasswordPayload,
  ResetPasswordPayload,
} from "@/shared/interfaces";
import { ResetPasswordRequestPayload, ResetPasswordResponse, SetNewResetPasswordPayload } from "@/shared/interfaces/auth";

const authApi = {
  login: ({ email, password }: LoginPayload): Promise<IResponse<LoginResponse>> => {
    const url = "/auth/login";
    return axiosClient.post(url, { email, password });
  },
  verifyCode: ({ authorizationToken, authCode }: AuthCodePayload): Promise<IResponse<AuthCodeResponse>> => {
    const url = "/auth/login/code";
    return axiosClient.post(url, { authorizationToken, authCode });
  },
  setNewPassword: ({ authorizationToken, password }: SetNewPasswordPasswordPayload): Promise<IResponse<AuthCodeResponse>> => {
    const url = "/auth/set-new-password";
    return axiosClient.post(url, { authorizationToken, password });
  },
  setNewResetPassword: ({ token, password }: SetNewResetPasswordPayload): Promise<IResponse<AuthCodeResponse>> => {
    const url = "/auth/set-new-reset-password";
    return axiosClient.post(url, { token, password });
  },
  resetPassword: ({ userId, password }: ResetPasswordPayload): Promise<IResponse<ResetPasswordResponse>> => {
    const url = "/auth/reset-password";
    return axiosClient.post(url, { userId, password });
  },
  resetPasswordRequest: ({ email }: ResetPasswordRequestPayload): Promise<IResponse<ResetPasswordResponse>> => {
    const url = "/auth/send-reset-password-email";
    return axiosClient.post(url, { email });
  },
};

export default authApi;
