import loadable from "../../shared/utils/loadable";
import { RouteProps } from "react-router-dom";
import { routeConstants } from "../../shared/constants";

const DeliveryRoute: RouteProps = {
  path: routeConstants.delivery.route,
  component: loadable(import("./index")),
};

export default DeliveryRoute;
