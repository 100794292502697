import { ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const integrationSetupActions = {
  getIntegrationSetups: (state: ISettingState) => {
    state.loading = true;
  },
  getIntegrationSetupsSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.integrationSetups = action.payload;
  },
  getIntegrationSetup: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getIntegrationSetupSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.integrationSetup = action.payload;
  },
  createIntegrationSetup: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createIntegrationSetupSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.success = "Created Successfully";
  },
  updateIntegrationSetup: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  updateIntegrationSetupSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully";
  },
  removeIntegrationSetup: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  removeIntegrationSetupSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Removed Successfully";
  },
};
