import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorData } from "@/shared/interfaces";

export interface ReportState {
  loading: boolean;
  customerReports: any[];
  deliveryReports: any[];
  error?: ErrorData | null;
  success: boolean;
}

const initialState: ReportState = {
  loading: false,
  customerReports: [],
  deliveryReports: [],
  success: false,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    reset: () => initialState,
    getCustomerReports: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    getCustomerReportsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.customerReports = action.payload.items;
      state.error = null;
      state.success = true;
    },
    getCustomerReportsFailed: (state, action: PayloadAction<ErrorData | undefined>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    getDeliveryReports: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    getDeliveryReportsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.deliveryReports = action.payload.items;
      state.error = null;
      state.success = true;
    },
    getDeliveryReportsFailed: (state, action: PayloadAction<ErrorData | undefined>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
  },
});

// Delivery actions
export const reportActions = reportSlice.actions;

// Delivery reducer
export default reportSlice.reducer;
