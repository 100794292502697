import { ErrorData } from "@/shared/interfaces";
import { ISettingState } from "@/shared/interfaces/setting";
import { PayloadAction } from "@reduxjs/toolkit";

export const routerConfigActions = {
  getRouterConfigs: (state: ISettingState) => {
    state.loading = true;
  },
  getSuggestions: (state: ISettingState) => {
    state.loading = true;
  },
  getSuggestionsSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.suggestions = {
      ...action.payload,
    };
  },
  getRouterConfigsSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.routerConfigs = action.payload;
  },
  getRouterConfigsFailed: (state: ISettingState, action: PayloadAction<ErrorData | undefined>) => {
    state.loading = false;
    state.error = action.payload;
  },
  createRouterConfig: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  createRouterConfigSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.success = "Created Successfully";
  },
  getRouterConfig: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  getRouterConfigSuccess: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = false;
    state.routerConfig = action.payload;
  },
  resetRouterConfig: (state: ISettingState) => {
    state.routerConfig = undefined;
  },
  updateRouterConfig: (state: ISettingState, action: PayloadAction<any>) => {
    state.loading = true;
  },
  updateRouterConfigSuccess: (state: ISettingState) => {
    state.loading = false;
    state.success = "Updated Successfully";
  },
};
