import { Typography } from "@mui/material";
import React, { Suspense, lazy } from "react";

const loadable = (importFunc: any) => {
  const LazyComponent = lazy(() => importFunc);
  return (props: any) => (
    <Suspense fallback={<Typography>Loading...</Typography>}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
