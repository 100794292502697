import React from "react";
import { Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "@mui/material";
import { history } from "./shared/utils/history";
import theme from "./theme/index";

import routers from "./screens/routes";
import GuardRoute from "./shared/utils/GuardRoute";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <Switch>
          {routers.map((route, index) => (
            <GuardRoute
              isPrivate={!["/login", "/set-password", "/reset-password-request"].includes(route.path as string)}
              key={index}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </ConnectedRouter>
    </ThemeProvider>
  );
}

export default App;
